import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import AuthForm from 'components/AuthForm/Loadable';
import queryString from 'query-string';
import * as authActions from 'redux/modules/auth';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import { withSnackbar } from 'notistack';
import idx from 'idx';
import autoBind from 'auto-bind';

@connect(
  state => ({
    user: state.auth && state.auth.user,
    auth: state.auth,
    redirect: state.redirect
  }),
  { ...authActions }
)
@withTranslation(['common'])
@withRouter
@withSnackbar
export default class Login extends Component {
  static propTypes = {
    user: PropTypes.objectOf(PropTypes.any).isRequired,
    magicLinkAuthentication: PropTypes.func.isRequired,
    login: PropTypes.func.isRequired,
    enqueueSnackbar: PropTypes.func.isRequired,
    auth: PropTypes.objectOf(PropTypes.any),
    redirect: PropTypes.objectOf(PropTypes.any),
    history: PropTypes.objectOf(PropTypes.any).isRequired,
    location: PropTypes.objectOf(PropTypes.any).isRequired,
    t: PropTypes.objectOf(PropTypes.any).isRequired
  };

  static defaultProps = {
    auth: {},
    redirect: {}
  };

  static contextTypes = {
    router: PropTypes.object
  };

  constructor(props) {
    super(props);

    autoBind.react(this);

    this.state = {
      errorMessage: '',
    };
  }

  componentDidMount() {
    const { magicLinkAuthentication } = this.props;
    const search = idx(this.props, _ => _.location.search);
    const { code, state = '' } = queryString.parse(search);
    const { type } = this.getFromFacebookState(state);

    if (type === 'facebookdirect') return;
    if (!code) return;

    magicLinkAuthentication({ code });
  }

  componentWillReceiveProps(nextProps) {
    const {
      location,
      t,
      enqueueSnackbar,
      history,
    } = this.props;

    const redirect = idx(nextProps, _ => _.redirect);

    const accessToken = idx(nextProps, _ => _.user.owt.access_token);
    const _accessToken = idx(this.props, _ => _.user.owt.access_token);

    const magicLinkLogginIn = idx(nextProps, _ => _.auth.magicLinkLogginIn);
    const _magicLinkLogginIn = idx(this.props, _ => _.auth.magicLinkLogginIn);

    const errorCode = idx(nextProps, _ => _.auth.errorCode);
    const errorMessage = idx(nextProps, _ => _.auth.error.message);

    const { search = {} } = location;
    const { redirect: redirectFromUrlQuery, state: facebookState } = queryString.parse(search);

    /* When Facebook login using redirect method
     * Format:
     * {
     *   "type": "facebookdirect",
     *   "redirectTo": "${window.location.href}"
     * }
     */
    const { redirectTo: facebookRedirect } = this.getFromFacebookState(facebookState);

    if (errorCode) {
      this.setState({
        errorMessage: `${errorMessage}. Error code: ${errorCode}`
      });
    }

    if (
      (!_accessToken && accessToken) || // when auth weren't there
      (_magicLinkLogginIn && !magicLinkLogginIn && accessToken) // when auth already exist
    ) {
      enqueueSnackbar(t('AUTH.SUCCESS_LOGIN_MESSAGE'), {
        variant: 'success',
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        }
      });

      /**
       * Handle Redirection Cases
       */
      if (redirectFromUrlQuery) {
        console.log('redirect is from url query', redirectFromUrlQuery);
        return history.push(redirectFromUrlQuery);
      }

      // Redirect set by Facebooking login dialog
      if (facebookRedirect) {
        console.log('redirect by facebook state case', facebookRedirect);
        return history.push(facebookRedirect);
      }

      /**
       * Redirect with redirect redux state
       */
      if (typeof redirect === 'string') {
        console.log('redirect props is a string case', redirect);
        return history.push(redirect);
      }

      if (redirect && redirect.redirect) {
        console.log('redirect props is a obj case', redirect);
        return history.push(redirect.redirect);
      }

      /**
       * Redirect default case
       */
      console.log('redirect props is a default case', redirect);
      history.push('/');
    }
  }

  getFromFacebookState(state) {
    /* When Facebook login using redirect method
     * Format:
     * {
     *   "type": "facebookdirect",
     *   "redirectTo": "${window.location.href}"
     * }
     */
    let redirectTo = '';
    let type = '';

    try {
      const {
        redirectTo: _redirectTo = '',
        type: _type = ''
      } = state ? JSON.parse(state) : {};

      redirectTo = _redirectTo;
      type = _type;
    } catch (e) {
      console.log(e);
    }

    return { redirectTo, type };
  }

  render() {
    const styles = require('./Login.scss');
    const { errorMessage } = this.state;
    const { history } = this.props;
    const search = idx(this.props, _ => _.location.search);
    const { redirect } = queryString.parse(search);

    return (
      <div className="container">
        <Helmet title="Login" />
        <div>
          <div className={styles.authFormContainer}>
            <AuthForm
              hideHeader
              handleDialogClose={() => {
                if (redirect) {
                  return history.push(redirect);
                }

                return history.push('/');
              }}
            />
          </div>
          {
            this.state.errorMessage
              ? <span
                className="flex justify-center mt2 mb2"
                style={{
                  textAlign: 'center',
                  fontSize: '0.75rem',
                  color: '#8C9BAA'
                }}
              >{errorMessage}</span>
              : null
          }
        </div>
      </div>
    );
  }
}
